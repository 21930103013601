import stickybits from 'stickybits';

export default class StickyElements{
  get SELECTORS(){
    return{
      elements: '[data-sticky="true"]'
    }
  }
  init(){
    stickybits(this.SELECTORS.elements);
  }
}