/**
 * Created by wipsteve on 19/11/2019.
 */
import axios from 'axios';

export default class Bookmarker {
  get SELECTORS () {
    return {
      triggers: '.js-bookMark'
    }
  }
  
  constructor () {
    this.cssLoadingClass = 'is-loading';
    this.cssFavClass = 'js-isFav';
    this.csrf = null;
    this.inputNames = {
      articleId : 'article_id',
      favourite: 'favourite'
    }
  }
  
  init () {
    if(document.querySelector(this.SELECTORS.triggers)){
      this.setCsrf();
      this.listenTriggers();
    }
  }
  setCsrf(){
    try{
      let csrfMeta = document.querySelector('meta[name="csrf"]')
      if(csrfMeta === null){
        throw 'Meta tag mancante nel header con i valori name=csrf e relativo contenuto';
      }
      this.csrf = csrfMeta.content;
      
    }catch(err){
      console.error(err);
    }
  }
  listenTriggers(){
    let triggers = document.querySelectorAll(this.SELECTORS.triggers);
    for (let trigger of triggers){
      let endpoint = trigger.dataset.endpoint;
      let idArticle = trigger.dataset.articleId;
      let favInitialState = this.isFavourite(trigger)?this.cssFavClass:'';
      let nextState = this.isFavourite(trigger);
      let form = new FormData();
      form.append(this.inputNames.articleId,idArticle);
      form.append(this.inputNames.favourite,!nextState);
      
      trigger.addEventListener('click', ()=>{
        console.log(nextState);
        this.isLoading(trigger);
        this.callApi(endpoint,form,(resp)=>{
            console.log(resp);
            //TODO: once attached service check behaviour
          if(resp.status === 200){
            this.isLoading(trigger);
            this.setFav(trigger);
            nextState = !nextState;
          }else{
            this.isLoading(trigger);
          }
        },(err)=>{
          this.isLoading(trigger);
          trigger.classList.add(favInitialState);
        })
      });
    }
  }
  isFavourite(trigger){
    return trigger.classList.contains(this.cssFavClass);
  }
  callApi(endpoint,data,callback,errCb){
    axios.post(endpoint,data,{
      headers: {
        'X-CSRF-TOKEN': this.csrf
      }
    }).then(resp=>{
      if(typeof callback==='function'){
        callback(resp);
      }
    }).catch(err =>{
      if(typeof errCb==='function'){
        errCb(err);
      }
    })
  }
  
  isLoading(trigger){
    trigger.classList.toggle(this.cssLoadingClass);
  }
  setFav(trigger){
    if(trigger.classList.contains(this.cssFavClass)){
      trigger.classList.remove(this.cssFavClass);
    }else{
      trigger.classList.add(this.cssFavClass);
    }
  }
}