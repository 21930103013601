import axios from 'axios';
import Patterns from '../configs/Patterns';

export default class FormRegister{
  /**
   *
   * @param theForm :  Form Element
   * @param formGroupsClass :  class of form groups/input containers
   */
  constructor (theForm,formGroupsClass){
    this.formEl = theForm;
    this.formGroups = formGroupsClass;
    this.inputErrorClass = 'js-inputError';
    this.registeredField = 'registered';
    this.submitBtn=null;
  }
  init(){
    if(this.formEl){
      this.setPatterns();
      this.setSubmitBtn();
      this.listenSubmit();
    }
  }
  setSubmitBtn(){
    this.submitBtn = this.formEl.querySelector('button[type="submit"]');
    console.log(this.submitBtn);
  }
  setPatterns(){
    let requireds = this.formEl.querySelectorAll('input:required');
    for(let inputEl of requireds){
      if(inputEl.type === 'text'){
        inputEl.pattern = Patterns.text.source;
      }
      if(inputEl.type === 'email'){
        //inputEl.pattern = Patterns.email.source;
      }
      inputEl.addEventListener('blur',()=>{
        let errMsg = inputEl.dataset.errMsg?inputEl.dataset.errMsg:'';
        let isValid = inputEl.checkValidity();
        if(!isValid){
          this.setErrorInput(inputEl.name, errMsg);
        }else{
          this.setErrorInput(inputEl.name, '');
        }
      })
    }
  }
  async listenSubmit(){
    let trigger = await this.submitBtn;
    trigger.addEventListener('click',(e)=>{
      
      let isValidForm = this.formEl.checkValidity();
      console.log(this.submitBtn);
      let url = trigger.form.action;
      
      if(isValidForm){
        e.preventDefault();
        this.disableSubmit(true);
        this.postForm(`${url}`);
      }else{
        return false;
      }
      
    })
  }
  successForm(){
    this.formEl.classList.add('form-sent');
  }
  postForm(url){
    let formData = new FormData(this.formEl);
    
    axios( {
      method:'post',
      url: url,
      data: formData
    }).then((resp)=>{
      console.log(resp);
      let status = resp.status;
      let newLocation = resp.data.redirect;
      if(status=== 200){
        this.successForm();
        setTimeout(()=>{
          window.location.replace(`${newLocation}`);
  
        },2000);
      }
    }).catch((err)=>{
      let errors = err.response.data.errors;
      //console.log(errors);
      if(errors){
        this.resetErrors();
        for (let [key, value] of Object.entries(errors)) {
          //console.log(key, value);
          this.setErrorInput(key,value[0]);
        }
        this.disableSubmit(false);
      }
    })
  }
  disableSubmit(bool){
    this.submitBtn.disabled= bool;
  }
  
  isEmailRegistered(messageToTest){
    //Questa funzione mira semplicemente a testare con regex se nel messaggio di errore sul campo email ci sia la parola nella variabile regex. Se true verra gestito l'errore e l'aggiunta del link in setErrorInput.
    //TODO: fare l'improvement
    let regex = /registrata/gmi;
    return regex.test(messageToTest);
  }
  setErrorInput(inputName,errMessage){
    let inputEl = document.querySelector(`input[name="${inputName}"], select[name="${inputName}"], div[class="dummy-${inputName}"]`);
    let inputContainer = inputEl.closest(this.formGroups);
    inputContainer.classList.add(this.inputErrorClass);
    let errorSpan = inputContainer.querySelector('.error');
    errorSpan.innerText = errMessage;
    
    if(inputEl.type ==='email' && this.isEmailRegistered(errMessage)){
      inputContainer.classList.add(this.registeredField);
    }
  }
  resetErrors(){
    let inputContainers = document.querySelectorAll(this.formGroups);
    for(let group of inputContainers){
      if(group.classList.contains(this.inputErrorClass)){
        group.classList.remove(this.inputErrorClass);
        if(group.classList.contains(this.registeredField)){
          group.classList.remove(this.registeredField);
        }
      }
    }
  }
}