/**
 * Created by wipsteve on 13/12/2019.
 */

export default class PasswordToggleView {
  get SELECTORS () {
    return {
      toggleField: '.js-passToggleView',
      triggerEl: '.js-triggerToggleView',
      passInput : 'input[type="password"]'
    }
  }
  
  constructor () {
  }
  
  init () {
    if(document.querySelector(this.SELECTORS.toggleField)){
      let toggleFields = document.querySelectorAll(this.SELECTORS.toggleField);
      for(let toggleField of toggleFields){
        this.handleToggler(toggleField);
      }
    }
  }
  handleToggler(field){
    let trigger = field.querySelector(this.SELECTORS.triggerEl);
    let inputEl = field.querySelector(this.SELECTORS.passInput);
    let toType= 'text';
    trigger.addEventListener('click', ()=>{
      let currentType = inputEl.type;
      inputEl.type = toType;
      toType = currentType;
    });
  }
}