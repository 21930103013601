export default class AudioPlayer{
  get SELECTORS(){
    return{
      playerBlock: '.js-audioBlock',
      audioTag : '.js-audioTag',
      playBtn : '.js-playBtn',
      progressContainer : '.js-audioProgressBar',
      progressBar: '.js-audioProgress',
      currTime: '.js-audioCurrTime',
      totalTime : '.js-audioTotalTime',
    }
  }
  constructor (playerBlockEl = document.querySelector(this.SELECTORS.playerBlock)){
    this.playerBlock = playerBlockEl;
    this.playCss = 'play';
    this.cssPlaying = 'playing';
  }
  async init(){
    let audioEl = await this.getAudio();
    this.initPlayer(audioEl)
  }
  
  getAudio(){
    let audioTag = this.playerBlock.querySelector(this.SELECTORS.audioTag);
    return audioTag;
  }
  initPlayer(audioEl){
    let playBtn = this.playerBlock.querySelector(this.SELECTORS.playBtn);
    //console.log(audioEl);
    playBtn.disabled = false;
    let progressBarContainer = this.playerBlock.querySelector(this.SELECTORS.progressContainer);
    let progress = this.playerBlock.querySelector(this.SELECTORS.progressBar);

    let spanCurrTime = this.playerBlock.querySelector(this.SELECTORS.currTime);
    let spanTotalTime = this.playerBlock.querySelector(this.SELECTORS.totalTime);
    //set max value to progress tag
    if(audioEl.readyState >=2){
      console.log('Audio Ready');
      progress.style.width = 0;
      this.setTimePlayer(spanTotalTime,audioEl.duration);
    }else{
      audioEl.addEventListener('loadeddata',()=>{
        //console.log(audioEl);
        progress.style.width = 0;
        this.setTimePlayer(spanTotalTime,audioEl.duration);
        this.listenProgressBar(audioEl,progressBarContainer);
      });
    }
    
    audioEl.addEventListener('timeupdate',()=>{
      progress.style.width = `${(audioEl.currentTime/audioEl.duration)*100}%`;

      this.setTimePlayer(spanCurrTime,audioEl.currentTime);
    });
    audioEl.addEventListener('ended', ()=>{
      playBtn.classList.remove(this.playCss);
    });
    
    playBtn.addEventListener('click',()=>{
      
      if(audioEl.paused || audioEl.ended){
        console.log('Play Audio');
        audioEl.play();
        playBtn.classList.add(this.playCss);
        progressBarContainer.classList.add(this.cssPlaying);
      }else{
        console.log('Pause Audio');
        audioEl.pause();
        playBtn.classList.remove(this.playCss);
        progressBarContainer.classList.remove(this.cssPlaying);
      }
    });
    
  }
  listenProgressBar(audioEl, progressBar){
    progressBar.addEventListener('click',(e)=>{
      let pointerPos = e.offsetX;
      let totalTime = audioEl.duration;
      let percentBar = (pointerPos * 100)/progressBar.offsetWidth;
      let newAudioPosition = (percentBar * totalTime)/100;
      audioEl.currentTime = newAudioPosition;
    })
  }
  /**
   * Set current Time into element
   * @param element - element container
   * @param currentTime - current time
   */
  setTimePlayer(element,currentTime){
    let minutes = `0${Math.floor(currentTime/ 60)}`;
    let seconds = `0${Math.floor(currentTime % 60)}`;
    element.innerText = `${minutes.substr(-2)}: ${seconds.substr(-2)}`;
  }
}