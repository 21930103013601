export default class MenuHandler{
  
  get SELECTORS (){
    return{
      menu: '[data-menu]',
      triggers: '[data-target-menu]',
      svgEl: '.hamburger-svg',
    }
  }
  constructor(){
    this.cssOpen = 'show-menu';
    this.cssSvgToggle = 'to-close';
  }
  menuExists(){
    let menu = document.querySelectorAll(this.SELECTORS.menu);
    return menu.length>0;
  }
  init(){
    if(this.menuExists()){
      this.handleMenu();
    }
  }
  getMenu(selector){
    return document.querySelector(`[data-menu="${selector}"]`);
  }
  handleMenu(){
    let triggers = document.querySelectorAll(this.SELECTORS.triggers);
    const handlerClick = async (event)=>{
      console.log(event.target);
      if(event.target.classList.contains(this.cssOpen)){
        return;
      }else{
      
      }
    };
    
    for (let trigger of triggers){
      let svgEl = trigger.querySelector(this.SELECTORS.svgEl);
      trigger.addEventListener('click',()=>{
        console.log(trigger);
        if(svgEl){
          svgEl.classList.toggle(this.cssSvgToggle);
        }
        let menu = this.getMenu(trigger.dataset.targetMenu);
        this.toggleMenu(menu);
      });
    }
    
  }
  
  bodyElement(){
    return document.querySelector('body');
  }
  toggleMenu(menu){
    menu.classList.toggle(this.cssOpen);
  }
  openMenu(menu){
    menu.classList.add(this.cssOpen);
  }
  closeMenu(menu){
    menu.classList.remove(this.cssOpen);
  }
}