import HeaderNav from './components/HeaderNav';
import MenuHandler from './components/MenuHandler';
import FormRegister from './components/FormRegister';
import AudioPlayer from './components/AudioPlayer';
import DatePicker from './components/DatePicker';
import StickyElements from './components/StickyElements';
import Bookmarker from './components/Bookmarker';
import FormInviteFriend from './components/FormInviteFriend';
import FormSubscription from './components/FormSubscription';
import FormGift from './components/FormGift';
import FlashMessagesHandler from './components/FlashMessagesHandler';
import PayPalCheckout from './components/PayPalCheckout';
import PasswordToggleView from './components/PasswordToggleView';

class App {
  init(){
    this.setHeader();
    this.setMenuHandler();
    this.setStickyElements();
    this.setAudioPlayers();
    this.setDatePicker();
    this.setRegisterForm();
    this.setBookmarker();
    this.setInviteFriendForm();
    this.setFlashBoxHandler();
    this.setPayPaylCheckout();
    this.setToggleViewPass();
    this.setSubscriptionForm();
    this.setGiftForm();
  }
  setMenuHandler(){
    let handler = new MenuHandler();
    handler.init();
  }
  setHeader(){
    let handler = new HeaderNav();
    handler.init();
  }
  setAudioPlayers(){
    let audioBlock = document.querySelectorAll('.js-audioBlock');
    for(let block of audioBlock){
      let player = new AudioPlayer(block);
      player.init();
    }
  }
  setDatePicker(){
    let datepicker = new DatePicker();
    datepicker.init();
  }
  setStickyElements(){
    let sticky = new StickyElements();
    sticky.init();
  }
  setRegisterForm(){
    let form = document.querySelector('.form-register__form')
    let handler = new FormRegister(form,'.js-regField');
    handler.init();
  }
  setSubscriptionForm(){
    let form = document.querySelector('.js-subscrForm')
    if(form){
      let formHandler = new FormSubscription(form);
      formHandler.init();
    }
  }
  setGiftForm(){
    let form = document.querySelector('.js-giftForm');
    if(form){
      let formHandler = new FormGift(form);
      formHandler.init();
    }
  }
  setBookmarker(){
    let marker = new Bookmarker();
    marker.init();
  }
  setInviteFriendForm(){
    let form = new FormInviteFriend();
    form.init();
  }
  setFlashBoxHandler(){
    let handler = new FlashMessagesHandler();
    handler.init();
  }
  setPayPaylCheckout(){
    let paypal = new PayPalCheckout();
    paypal.init();
  }
  setToggleViewPass(){
    let toggler = new PasswordToggleView();
    toggler.init();
  }
}

let app = new App();
app.init();
