/**
 * Created by wipsteve on 10/12/2019.
 */

export default class FlashMessagesHandler {
  get SELECTORS () {
    return {
      flashBox : '.js-flashMessage',
      closeFlashBtn: '.js-closeFlash'
    }
  }
  
  constructor () {
    this.cssHide = 'hide';
    this.cssOverflowBody='no-scroll';
  }
  getFlashModal(){
    return document.querySelector(this.SELECTORS.flashBox);
  }
  init () {
    if(this.getFlashModal()){
      this.handleFlashBox();
    }
  }
  handleFlashBox(){
    let closeBtn  = this.getFlashModal().querySelector(this.SELECTORS.closeFlashBtn);
    let bodyEl = document.querySelector('body');
    bodyEl.classList.add(this.cssOverflowBody);
    closeBtn.addEventListener('click',()=>{
      this.getFlashModal().classList.add(this.cssHide);
      bodyEl.classList.remove(this.cssOverflowBody);
    })
  }
}