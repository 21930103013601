/**
 * Created by wipsteve on 10/01/2020.
 */

export default class FormGift {
  get SELECTORS () {
    return {
      amountField: '.js-amountField',
      currencyField: '.js-currencyField',
      switcherAmount: '.js-switcherAmount',
      methodPayment: '.js-methodPayment',
    }
  }
  
  constructor (formEl) {
    this.form = formEl;
    this.methodsToToggle = ['paypal','credit-card'];
    this.amountField=null;
    this.optionsBackup=[];
    this.currencyBackup=null;
  }
  
  init () {
    try{
      this.setListenerOptionCC();
    }catch (error){
      console.error(error);
    }
  }
  setListenerOptionCC(){
    let ccValue = 'credit-card';
    let CHFcurr= 'CHF';
    let currField = this.form.querySelector(this.SELECTORS.currencyField);
    let selectInputCurrField = currField.querySelector('select');
    let paymentMethod = this.form.querySelector(this.SELECTORS.methodPayment);
    //TODO: Improve this 'pattonata'
    if(paymentMethod.value=== ccValue){
      this.currencyBackup = selectInputCurrField;
      currField.style.display='none';
      this.createHiddenInput(currField,selectInputCurrField.name,CHFcurr);
      selectInputCurrField.remove();
    }
    paymentMethod.addEventListener('change',()=>{
      if(paymentMethod.value=== ccValue){
        this.currencyBackup = selectInputCurrField;
        currField.style.display='none';
        this.createHiddenInput(currField,selectInputCurrField.name,CHFcurr);
        selectInputCurrField.remove();
      }else if(this.currencyBackup!==null && paymentMethod.value!== ccValue){
        this.deleteHiddenInput(currField, selectInputCurrField.name);
        currField.insertBefore(this.currencyBackup,currField.childNodes[2]);
        currField.style.display= '';
        this.currencyBackup=null;
      }
    });
  }
  createHiddenInput(field, inputName,value){
    let inputEl = document.createElement('input');
    inputEl.type='hidden';
    inputEl.name = inputName;
    inputEl.value = value;
    field.append(inputEl);
  }
  deleteHiddenInput(field){
    field.querySelector(`input[type="hidden"]`).remove();
  }
  setListenerSwitcher(){
    let switcher = this.form.querySelector(this.SELECTORS.switcherAmount);
    let compareVal = switcher.dataset.switchOn;
    switcher.addEventListener('change',(e)=>this.switchHandler(e,compareVal));
  }
  switchHandler(event,compareVal){
    let currentVal = event.target.value;
    //console.log(this.amountField);
    if(currentVal===compareVal){
      //this.deletePaypalOption(true);
      for(let option of this.methodsToToggle){
        this.deleteMethodOption(option,true);
      }
      this.addAmountField(true);
    }else{
      for(let option of this.methodsToToggle){
        this.deleteMethodOption(option,false);
      }
      this.addAmountField(false);
      this.optionsBackup = [];
    }
  }
  
  deleteMethodOption(option, bool){
    let paymentSelect = this.form.querySelector(this.SELECTORS.methodPayment);
    if(bool===true){
      this.deleteOption(paymentSelect,option);
    }else if(bool===false){
      this.addOption(paymentSelect,option);
    }
  }
  
  addAmountField(bool){
    let amountField = this.form.querySelector(this.SELECTORS.amountField);
    if(bool===true){
      for(let child of this.amountField){
        amountField.append(child);
      }
    }else if(bool===false){
      this.deleteContentField(amountField);
    }
  }
  
  getAmountField(){
    let amountField = this.form.querySelector(this.SELECTORS.amountField);
    if(!amountField){
      throw 'Amount field mancante in pagina o mancata dichiarazione del field col rispettivo selector (.js-amountField)'
    }
    this.amountField = Array.from(amountField.children);
    this.deleteContentField(amountField);
  }
  
  addOption(input,value){
    let optionEl = this.optionsBackup.filter(method => method.value===value);
    //console.log(optionEl);
    if(optionEl.length>0){
      input.append(optionEl[0]);
    }
  }
  
  deleteOption(input,value){
    let inputEl = input.querySelector(`option[value='${value}']`);
    this.optionsBackup.push(inputEl);
    inputEl.parentNode.removeChild(inputEl);
    console.log(this.optionsBackup);
  }
  
  deleteContentField(field){
    field.innerHTML = '';
  }
}