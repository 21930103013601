import flatpickr from 'flatpickr';

export default class DatePicker{
  get SELECTORS(){
    return{
      inputEl : '.js-datePicker'
    }
  }
  get CONFIG(){
    return {
      altInput: true,
      maxDate: 'today',
      dateFormat: 'Y-m-d',
      altFormat:'d/m/y',
      disableMobile:true,
    }
  }
  init(){
    if( document.querySelector(this.SELECTORS.inputEl)){
      this.startPicker();
    }
  }
  startPicker(){
    let allInputs = document.querySelectorAll(this.SELECTORS.inputEl);
    for(let inputEl of allInputs){
      flatpickr(inputEl,this.CONFIG);
    }
  }
  
}