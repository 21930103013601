/**
 * Created by wipsteve on 13/12/2019.
 */
import axios from 'axios';


export default class PayPalCheckout {
  get SELECTORS () {
    return {
      btnContainer: '#paypal-button',
      loader: '.loader',
      mainLayout: '.js-checkoutLayout',
      loaderBlock : '.js-checkoutLoader',
      ctaLink : '.js-checkoutBackHome'
    }
  }

  constructor () {
    this.route=null;
    this.cssLoading = 'loading';
    this.cssLoaded = 'loaded';
  }
  getBtnContainer(){
    return document.querySelector(this.SELECTORS.btnContainer);
  }
  async init (){
    if(this.getBtnContainer() && window.paypal){
      this.route = this.getBtnContainer().dataset.route;
      this.value = 12;
      this.handlerPaypalBtn(window.paypal);
    }
  }
  setLinkHref(hrefData){
    let link = document.querySelector(this.SELECTORS.ctaLink);
    link.href = hrefData;
  }
  loadingState(cssClass){
    let checkoutLayout = document.querySelector(this.SELECTORS.mainLayout);
    checkoutLayout.classList.add(cssClass);
  }
  loadedMessage(message){
    let loaderSection = document.querySelector(this.SELECTORS.loaderBlock);
    loaderSection.innerText = message;
  }
  handlerPaypalBtn(paypal){
    let totalAmount = Number(this.getBtnContainer().dataset.totalAmount);
    let customId = this.getBtnContainer().dataset.orderId;
    let route = this.route;
    let csrf = this.getBtnContainer().dataset.csrf;
    let loading = ()=>{
      this.loadingState(this.cssLoading);
    };
    let loaded = (message,hrefLink)=>{
      this.setLinkHref(hrefLink);
      this.loadingState(this.cssLoaded);
      this.loadedMessage(message);
    };

    paypal.Buttons({
      //style:{
      //  layout: 'horizontal',
      //},
      createOrder: function (data, actions) {
        // This function sets up the details of the transaction, including the amount and line item details.
        return actions.order.create({
          purchase_units: [{
            amount: {
              value: totalAmount,
            },
            custom_id: customId
          }],
          application_context: {
            shipping_preference: 'NO_SHIPPING'
          }
        });
      },
      onApprove: function (data, actions) {
          loading();

          axios.post(route,{
            orderID: data.orderID,
            _token: csrf
          }).then(res => {
            console.log(res);
            if(res.status === 200){
              loaded(res.data.success_message,res.data.redirect);
              //setTimeout( ()=>{
              //  window.location.replace(`${res.data.redirect}`);
              //},2000);
            }
          }).catch( err =>{
            console.log(err);
          });
      },
    }).render('#paypal-button');
  }
}
